import 'tom-select/dist/css/tom-select.css';
import 'intl-tel-input/build/css/intlTelInput.css';

import TomSelect from 'tom-select';
import { getCurrentScreenSize } from '../modules-custom/functions';
import { W8ShippingAPIClient } from '../modules-custom/W8_ShippingAPIClient';

const { translations } = theme;
const client = new W8ShippingAPIClient(
  theme.api.endpointW8,
  theme.api.nonce,
  theme.i18n.locale_pll,
);

TomSelect.define('add_readonly', function () {
  this.hook('after', 'sync', function () {
    if (Object.keys(this.options).length < 5) {
      this.control.querySelector('input').setAttribute('readonly', 'readonly');
    } else {
      this.control.querySelector('input').removeAttribute('readonly');
    }
  });
});

TomSelect.define('search_meta', function () {
  this.hook('before', 'setup', () => {
    const reset_btn = document.createElement('div');
    reset_btn.classList.add(
      'ml-auto',
      'self-center',
      'text-raven-lighter',
      'hover:text-raven-base',
      'cursor-pointer',
    );
    reset_btn.innerHTML = `
			<svg class="fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0462 15.6645C14.4931 16.1114 15.2177 16.1114 15.6647 15.6645C16.1116 15.2175 16.1116 14.4929 15.6647 14.046L11.6186 9.99995L15.6645 5.95409C16.1114 5.50717 16.1114 4.78255 15.6645 4.33563C15.2175 3.8887 14.4929 3.8887 14.046 4.33563L10.0001 8.38148L5.95385 4.3352C5.50692 3.88827 4.78231 3.88827 4.33538 4.3352C3.88845 4.78212 3.88845 5.50673 4.33538 5.95366L8.38167 9.99995L4.3352 14.0464C3.88827 14.4934 3.88827 15.218 4.3352 15.6649C4.78212 16.1118 5.50674 16.1118 5.95366 15.6649L10.0001 11.6184L14.0462 15.6645Z" fill="#111111" fill-opacity="0.48"/>
			</svg>
		`;

    reset_btn.addEventListener('click', (e) => {
      this.input.value = '';
      this.control_input.value = '';
      // this.control_input.focus();
      e.preventDefault();
      e.stopPropagation();
    });

    this.control.append(reset_btn);

    this.control_input.addEventListener('input', (e) => {
      this.input.value = e.target.value;
    });
    // Перекидаємо фокус на інпут TS при фокусі на прихований інпут
    this.input.addEventListener('focus', () => {
      this.control_input.focus();
    });
    this.control_input.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        const searchForm = document.getElementById('search-form');
        if (searchForm && searchForm.checkValidity()) {
          searchForm.submit();
        }
      }
    });

    const wrapDropdown = document.createElement('div');
    wrapDropdown.classList.add('ts-dropdown-wrap');

    const searchMeta = document.createElement('div');
    searchMeta.classList.add(
      'flex',
      'flex-col',
      'flex-none',
      'gap-3',
      'text-base',
      'pb-5',
      'sm:pr-3',
      'border-b',
      'sm:border-b-0',
      'sm:border-r-2',
      'border-r-mist-darker',
      'overflow-y-auto',
      'scrollbar-thin',
      'sm:w-[30%]',
      'sm:flex-none',
    );

    const lastSearch = getLastSearchFromLocalStorage('last_search');

    if (lastSearch.length) {
      searchMeta.insertAdjacentHTML(
        'beforeend',
        `
				<div>
					<h3 class="text-[13px] leading-5 text-steel-dark mb-2">${theme.translations.ts.search_history}</h3>
					<ul id="search-meta-last" class="flex flex-col gap-2"></ul>
				</div>
			`,
      );

      const lastSearchList = searchMeta.querySelector('#search-meta-last');

      lastSearch.forEach((search) => {
        const button = document.createElement('button');
        button.classList.add('flex', 'items-center', 'w-full');
        button.type = 'button';
        button.innerHTML = `
					<svg class="stroke-current text-steel-light mr-1.5 group-hover:text-steel-darker" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.83334 4.66667H2.91667V1.75M3.08643 9.54154C3.63 10.3786 4.42759 11.0193 5.36211 11.3696C6.29663 11.7199 7.31909 11.7614 8.27894 11.488C9.23878 11.2146 10.0854 10.6408 10.6951 9.85059C11.3047 9.06039 11.6451 8.09551 11.666 7.0977C11.6869 6.09988 11.3873 5.12167 10.8113 4.30664C10.2353 3.49161 9.41302 2.88283 8.46546 2.56946C7.5179 2.25608 6.49489 2.25458 5.54651 2.56547C4.59814 2.87636 3.77452 3.48305 3.19638 4.29658" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
					<span class="truncate flex-1 text-start">${search}</span>
					<svg class="stroke-current text-steel-dark group-hover:text-steel-darker" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.6667 14.6666L7.33333 7.33331M7.33333 7.33331V12.8333M7.33333 7.33331H12.8333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
				`;

        button.addEventListener('click', () => {
          this.load(search);
          this.control_input.value = search;
          this.input.value = search;
        });

        // Створюємо елемент <li> та вставляємо в нього кнопку
        const listItem = document.createElement('li');
        listItem.classList.add('group');
        listItem.appendChild(button);

        lastSearchList.appendChild(listItem);
      });
    }

    searchMeta.insertAdjacentHTML(
      'beforeend',
      `
			<div>
				<h3 class="text-[13px] leading-5 text-steel-dark mb-2">${theme.translations.ts.popular_cats}</h3>
				<ul class="flex flex-col gap-2">
					${theme.site.get_top_viewed_categories
            .map(
              (category) => `
						<li class="group">
							<a
								target="_blank"
								class="flex gap-1 group-hover:text-steel-darker group-active:text-steel-dark"
								href="${category.link}"
							>
								<span class="truncate flex-1 text-start">${category.name}</span>
								<svg class="stroke-current text-steel-dark group-hover:text-steel-darker" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.50011 4L10.2862 9.20595C10.4137 9.38128 10.4137 9.6188 10.2862 9.79413L8.50011 12.25L6.50011 15" stroke-width="2.5" stroke-linecap="round"/></svg>
							</a>
						</li>
					`,
            )
            .join('')}
				</ul>
			</div>
		`,
    );

    const right = document.createElement('div');
    right.classList.add('ts-dropdown-right');

    this.all_results_html = document.createElement('a');
    this.all_results_html.id = 'search-meta-all-results';
    this.all_results_html.classList.add('!hidden');
    this.all_results_html.innerText = theme.translations.ts.show_all_results;

    right.append(this.all_results_html);
    right.append(this.dropdown_content);

    wrapDropdown.append(searchMeta);
    wrapDropdown.append(right);

    this.dropdown.innerHTML = '';
    this.dropdown.append(wrapDropdown);

    if (['sm', 'xs'].includes(getCurrentScreenSize())) {
      // this.wrapper
      const wrapControl = document.createElement('div');
      wrapControl.classList.add('ts-control-wrap');

      const wrapControlClose = document.createElement('div');
      wrapControlClose.classList.add('ts-control-close');
      wrapControlClose.setAttribute('data-micromodal-close', '');
      wrapControlClose.innerHTML = `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.3614 3.51398C17.1496 4.08722 17.3239 5.19089 16.7506 5.97911L11.8584 12.7059L16.7506 19.4326C17.3239 20.2208 17.1496 21.3245 16.3614 21.8978C15.5732 22.471 14.4695 22.2967 13.8963 21.5085L8.55115 14.1591C7.9211 13.2927 7.9211 12.1191 8.55114 11.2528L13.8963 3.90322C14.4695 3.115 15.5732 2.94073 16.3614 3.51398Z" fill="white"/></svg>`;

      wrapControl.append(wrapControlClose);
      wrapControl.append(this.control);

      this.wrapper.prepend(wrapControl);
    }
  });
});

function addToLocalStorageArray(key, value) {
  // Отримуємо існуючий масив з Local Storage
  let existingArray = JSON.parse(localStorage.getItem(key)) || [];

  // Яукщо значення вже є в масиві, нічого не робимо
  if (existingArray.includes(value)) {
    return;
  }

  // Функція для перевірки на спільно кореневі слова
  function hasCommonRoot(word1, word2) {
    // Конвертуємо слова в нижній регістр для уніфікації
    const w1 = word1.toLowerCase();
    const w2 = word2.toLowerCase();
    // Перевіряємо, чи є одне слово підрядком іншого
    return w1 !== w2 && (w1.includes(w2) || w2.includes(w1));
  }

  // Функція для видалення елементів з спільними коренями
  function filterArray(array, newValue) {
    return array.filter((item) => !hasCommonRoot(item, newValue));
  }

  // Оновлюємо масив, додаючи нове значення на початок
  existingArray = [value, ...filterArray(existingArray, value)];

  // Обрізаємо масив до останніх 5 елементів
  if (existingArray.length > 5) {
    existingArray = existingArray.slice(0, 5);
  }

  // Зберігаємо оновлений масив назад в Local Storage
  localStorage.setItem(key, JSON.stringify(existingArray));
}

function getLastSearchFromLocalStorage(key) {
  // Отримуємо існуючий масив з Local Storage
  let existingArray = JSON.parse(localStorage.getItem(key)) || [];
  // Відсортуємо масив у зворотному порядку
  // let reversedArray = existingArray.reverse();
  // Повертаємо відсортований масив
  return existingArray.slice(-5);
}

let defaultSettings = {
  create: false,
  maxItems: 1,
  maxOptions: null,
  // sortField : [{field:'$score'}],
  sortField: function (a, b) {
    const item_a = this.items[a.id];
    const item_b = this.items[b.id];

    let value_a = item_a[item_a.sortField ?? 'text'];
    let value_b = item_b[item_b.sortField ?? 'text'];

    return /^\d+$/.test(value_a) && /^\d+$/.test(value_b)
      ? (+value_a > +value_b) - (+value_a < +value_b)
      : value_a.localeCompare(value_b);
  },
  render: {
    no_results: function (data, escape) {
      return `<div class="no-results">${translations.nothing_found_for} "${escape(data.input)}"</div>`;
    },
  },
  plugins: {
    clear_button: {
      className:
        'ml-auto self-center text-raven-lighter hover:text-raven-base pointer-events-none hidden [.has-items_&]:block [.has-items_&]:pointer-events-auto',
      title: translations.delete_the_selected_option,
      html: function (data) {
        return `<a href="javascript:void(0)" class="${data.className}" title="${data.title}">
					<svg class="fill-current" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fill-rule="evenodd" clip-rule="evenodd" d="M14.0462 15.6645C14.4931 16.1114 15.2177 16.1114 15.6647 15.6645C16.1116 15.2175 16.1116 14.4929 15.6647 14.046L11.6186 9.99995L15.6645 5.95409C16.1114 5.50717 16.1114 4.78255 15.6645 4.33563C15.2175 3.8887 14.4929 3.8887 14.046 4.33563L10.0001 8.38148L5.95385 4.3352C5.50692 3.88827 4.78231 3.88827 4.33538 4.3352C3.88845 4.78212 3.88845 5.50673 4.33538 5.95366L8.38167 9.99995L4.3352 14.0464C3.88827 14.4934 3.88827 15.218 4.3352 15.6649C4.78212 16.1118 5.50674 16.1118 5.95366 15.6649L10.0001 11.6184L14.0462 15.6645Z" fill="#111111" fill-opacity="0.48"/>
					</svg>
				</a>`;
      },
    },
    add_readonly: {},
  },
};

function setAutocomleate() {
  document.querySelectorAll('select')?.forEach(function (el) {
    if (
      el.tomselect ||
      el.classList.contains('no-tomselect') ||
      el.parentNode.classList.contains('facetwp-type-fselect')
    ) {
      return false;
    }

    let settings = { ...defaultSettings };

    if (el.parentNode.classList.contains('facetwp-facet-sorting')) {
      const translate = {
        '↑': '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="M229.7 93.6a8.1 8.1 0 0 1-11.4.1L192 67.3V144a8 8 0 0 1-16 0V67.3l-26.3 26.4a8.2 8.2 0 0 1-11.4 0a8.1 8.1 0 0 1 0-11.4l40-40h.1c.2-.2.4-.3.5-.5l.3-.2l.4-.3l.3-.2l.3-.2h.4l.3-.2h.4l.4-.2h4.6l.4.2h.4l.3.2h.4l.3.2l.3.2l.4.3l.3.2c.2.2.4.3.5.5h.1l40 40a8 8 0 0 1 0 11.3ZM48 136h72a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm0-64h56a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm136 112H48a8 8 0 0 0 0 16h136a8 8 0 0 0 0-16Z"/></svg>',
        '↓': '<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256"><path fill="currentColor" d="m229.7 173.7l-40 40h-.1c-.1.2-.3.3-.5.5l-.3.2l-.4.3l-.3.2l-.3.2h-.4l-.3.2h-.4l-.4.2h-4.6l-.4-.2h-.4l-.3-.2h-.4l-.3-.2l-.3-.2l-.4-.3l-.3-.2l-.5-.5h-.1l-40-40a8.1 8.1 0 0 1 11.4-11.4l26.3 26.4V112a8 8 0 0 1 16 0v76.7l26.3-26.4a8.1 8.1 0 0 1 11.4 11.4ZM120 120H48a8 8 0 0 0 0 16h72a8 8 0 0 0 0-16ZM48 72h136a8 8 0 0 0 0-16H48a8 8 0 0 0 0 16Zm56 112H48a8 8 0 0 0 0 16h56a8 8 0 0 0 0-16Z"/></svg>',
      };
      function replaceIcon(str) {
        for (let i = 0; i < str.length; i++) {
          const char = str[i];
          if (char in translate) {
            const span = document.createElement('span');
            span.innerHTML = translate[char];
            return [span, char];
          }
        }
        return str;
      }

      function optionSortingRender(item, escape) {
        const containerDiv = document.createElement('div');
        const textDiv = document.createElement('div');
        const [iconSvg, char] = replaceIcon(item.text);
        textDiv.className = 'capitalize';
        textDiv.textContent = escape(item.text.replace(char, ''));
        containerDiv.className = 'flex items-center gap-3';
        containerDiv.appendChild(iconSvg);
        containerDiv.appendChild(textDiv);
        return containerDiv;
      }

      settings.render = {
        option: optionSortingRender,
        item: optionSortingRender,
      };
    }
    if (el.classList.contains('width-icon')) {
      settings.render = {
        option: optionRender,
        item: itemRender,
      };
    }

    const select = new TomSelect(el, settings);
    if (!select.input.querySelector('option[selected]')) {
      select.clear();
    }
  });
}
let intlTelInput = null;
const intlTelInputLoaded = new CustomEvent('intlTelInputLoaded');
async function downloadIntlTelInput() {
  if (!intlTelInput) {
    const intlTelInputModule = await import('intl-tel-input');
    intlTelInput = intlTelInputModule.default;
  }
  return intlTelInput;
}

async function downloadIntlUtils() {
  await import('intl-tel-input/build/js/utils');
}

function setIntlTelInput(intlTelInput) {
  document.querySelectorAll('input[name="MobilePhone"]')?.forEach((input) => {
    if (window.intlTelInputGlobals.getInstance(input)) {
      return true;
    }
    const iti = intlTelInput(input, {
      initialCountry: 'pl',
      nationalMode: true,
      showSelectedDialCode: true,
      fixDropdownWidth: false,
      formatOnDisplay: true,
      // autoPlaceholder: false,
      i18n: theme.translations,
    });
    input.addEventListener('open:countrydropdown', function () {
      input.previousElementSibling.parentElement.classList.add('open');
    });
    input.addEventListener('close:countrydropdown', function () {
      input.previousElementSibling.parentElement.classList.remove('open');
    });
    input.addEventListener('countrychange', function () {
      const countryCode = iti.getSelectedCountryData().iso2;
      if (countryCode == 'ua') {
        input.placeholder = '50 12 34 567';
      }
    });
  });
  document.dispatchEvent(intlTelInputLoaded);
}

window.addEventListener('beforeunload', () => {
  const search = document.getElementById('search');
  if (search === null) {
    return;
  }

  search.value = '';
});

document.addEventListener('DOMContentLoaded', async () => {
  // TODO Call here setAutocomleate() and create Custom Event like "TomSelectLoaded"
  const search = document.getElementById('search');
  if (search === null) {
    return;
  }

  new TomSelect(search, {
    create: false,
    maxItems: 1,
    maxOptions: 18,
    valueField: 'link',
    labelField: 'title',
    searchField: 'query',
    openOnFocus: true,
    highlight: false,
    optionClass: '',
    loadThrottle: 300,
    sortField: function (a, b) {
      const item_a = this.items[a.id];
      const item_b = this.items[b.id];
      let value_a = item_a.relevance;
      let value_b = item_b.relevance;
      return +value_a > +value_b;
    },
    plugins: {
      search_meta: {},
    },
    // shouldLoad:function(query) {
    // 	if (query.length <= 2 || this.loading) {
    // 		return false
    // 	}
    // 	return true
    // },
    load: async function (query, callback) {
      // Abort the previous request if it exists
      if (this.abortController) {
        this.abortController.abort();
      }

      // Create a new AbortController instance for the current request
      this.abortController = new AbortController();
      const { signal } = this.abortController;

      // Clear previous search options
      this.all_results_html.classList.add('!hidden');
      this.clearOptions();

      client.get(
        'search',
        {
          lang: theme.i18n.language,
          s: encodeURIComponent(query),
        },
        {
          signal: signal,
          onSuccess: ({ items }) => {
            if (items.length) {
              addToLocalStorageArray('last_search', query);
            }
            // Handle showing all results if more than max options
            if (items.length > this.settings.maxOptions) {
              this.all_results_html.href = `${theme.pages.search.url}?_query=${encodeURIComponent(query)}`;
              this.all_results_html.classList.remove('!hidden');
            } else {
              this.all_results_html.classList.add('!hidden');
            }

            // Invoke the callback with search results
            callback(items);
            this.isOpen = false;
          },
          onError: () => {
            callback([]);
          },
        },
      );
      return true;
    },
    onChange: function (value) {
      window.location = value;
    },
    onFocus: function () {
      this.dropdown.style.display = 'block';
    },
    onDropdownClose: function () {
      this.input.value = '';
    },
    render: {
      no_results: function () {
        return `<div class="no-results">${theme.translations.ts.no_results}</div>`;
      },
      // loading:function(data,escape){
      // 	return '<div class="loading">Loadding...</div>';
      // },
      option: function (item) {
        // post.lot
        // post.page
        // post.post
        // taxonomy.category
        // taxonomy.lot_cat
        if (item.source === 'post.lot') {
          return `<a href="${item.link}" class="grid grid-cols-[105px_auto] lg:grid-cols-[140px_auto] items-center rounded-xl bg-mist-lighter border border-mist-dark pt-2 pb-4 px-3 gap-x-3 sm:py-1.5 [&.active]:bg-mist-lighter [&.active]:text-brand-base">
						<div class="rounded-xl -mt-2.5 -ml-4 sm:row-start-1 sm:row-end-5 sm:-my-1.5 sm:self-stretch">
							<img robots="noindex"
                class="size-full object-cover"
								loading="lazy"
								src="${item.thumb}"
								width="105"
								height="81" />
						</div>
						<p class="self-start h3-mobile sm:h3-desktop line-clamp-3">${item.title}</p>
						<p class="col-span-full pt-2 text-steel-darker sm:col-start-2 sm:col-span-1 lg:pt-1"><b>VIN:</b> ${item.vin}</p>
						<p class="col-span-full text-steel-darker sm:col-start-2 sm:col-span-1"><b>Copart Lot:</b> ${item.lot_id}</p>
						<p class="col-span-full text-steel-darker sm:col-start-2 sm:col-span-1">${item.current_bid}</p>
					</a>`;
        } else {
          return `<a href="${item.link}" class="card p-2.5 block rounded-xl [&.active]:bg-mist-lighter [&.active]:text-brand-base">
						<h3 class="text-[13px] leading-5 text-steel-dark mb-0.5">${item.source_name}</h3>
						<p class="line-clamp-1 h3-mobile sm:h3-desktop empty:hidden">${item.title}</p>
						<p class="text-base leading-5 line-clamp-2 empty:hidden">${item.content}</p>
					</a>`;
        }
      },
    },
  });
});
if (theme.is_pl) {
  document.addEventListener('DOMContentLoaded', async () => {
    // TODO Call here setAutocomleate() and create Custom Event like "TomSelectLoaded"
    downloadIntlTelInput().then((intlTelInput) => {
      setIntlTelInput(intlTelInput);
      downloadIntlUtils();
    });
  });

  document.addEventListener('facetwp-loaded', () => {
    if (intlTelInput) {
      setIntlTelInput(intlTelInput);
    } else {
      downloadIntlTelInput().then((intlTelInput) => {
        setIntlTelInput(intlTelInput);
        downloadIntlUtils().then(() => {
          document
            .querySelectorAll('input[name="MobilePhone"]')
            ?.forEach((input) => {
              const value = '+' + input.value.replace(/\D/g, '');
              const iti = window.intlTelInputGlobals.getInstance(input);
              const countryData = iti.getSelectedCountryData();
              const phoneStarts = '+' + countryData.dialCode;
              const mask = intlTelInputUtils
                .getExampleNumber(
                  countryData.iso2,
                  true,
                  intlTelInputUtils.numberType.MOBILE,
                )
                ?.replace(/\d/g, '0');
              const masked = IMask.pipe(value.replace(phoneStarts, ''), {
                mask: mask,
              });
              iti.setNumber(masked);
              if (iti.isValidNumber()) {
                input.parentElement.classList.add('valid');
              }
            });
        });
      });
    }
  });
}
setAutocomleate();
document.addEventListener('facetwp-loaded', () => {
  setAutocomleate();
});

function optionRender(item, escape) {
  const image = item.src
    ? `<div class="flex-none">
				<img width="30" loading="lazy" class="mix-blend-multiply" src="${escape(item.src)}" alt="${escape(item.text)}">
			</div>`
    : '';
  const title = item.text
    ? `<div class="truncate">
				${escape(item.text)}
			</div>`
    : '';
  // const count = item.count
  //   ? `<div class="flex-1 text-right text-sm text-gray-500 [.selected_&]:hidden">
  // 			(${escape(item.count)})
  // 		</div>`
  //   : '';
  return `
		<div class="flex items-center gap-3">
			${image}
			${title}
		</div>
	`;
}

function itemRender(item, escape) {
  const image = item.src
    ? `<div class="flex-none">
				<img width="30" loading="lazy" src="${item.src}" alt="${item.text}">
			</div>`
    : '';
  const title = item.text
    ? `<div class="truncate">
				${escape(item.text)}
			</div>`
    : '';
  return `
		<div class="flex items-center gap-3">
			${image}
			${title}
		</div>
	`;
}

function positionModalSearchContainer() {
  if (window.innerWidth < 1024) {
    return;
  }
  // Знайти елемент #search-form-placeholder
  const searchFormPlaceholder = document.getElementById(
    'search-form-placeholder',
  );
  if (!searchFormPlaceholder) {
    console.error('Element #search-form-placeholder not found.');
    return;
  }

  const wpadminbarHeight =
    document.getElementById('wpadminbar')?.clientHeight ?? 0;

  // Отримати позицію елемента відносно вікна
  const rectSearch = searchFormPlaceholder.getBoundingClientRect();

  const rectSearcnsNeighbor =
    searchFormPlaceholder.nextElementSibling.children[0].getBoundingClientRect();
  const left = rectSearch.left;
  const top = rectSearch.top - wpadminbarHeight;

  const searchForm = document.getElementById('search-form');
  const searchIpt = document.getElementById('search');
  const searchFormTs = searchForm.querySelector('.ts-control');
  const searchFormBtn = searchForm.querySelector('button[type="submit"]');

  // Знайти елемент #header nav
  const headerNav = document.querySelector('#header nav');
  if (!headerNav) {
    console.error('Element #header nav not found.');
    return;
  }

  // Отримати позицію елемента відносно вікна
  const rectNav = headerNav.getBoundingClientRect();
  const navRight = rectNav.right;
  const computedStyles = window.getComputedStyle(headerNav);
  const navPaddingRight = parseFloat(computedStyles.paddingRight);

  // Обчислити відстань від лівої точки #search-form-placeholder до правої точки #header nav
  const distance = navRight - left - navPaddingRight;

  // Знайти modal-search-container
  const modalSearchContainer = document.getElementById(
    'modal-search-container',
  );
  if (!modalSearchContainer) {
    console.error('Element #modal-search-container not found.');
    return;
  }

  const additionalWidths = {
    sm: 0,
    md: 0,
    lg: 380,
    xl: 380,
    '2xl': 0,
  };

  const screenSize = getCurrentScreenSize();
  const additionalWidth = additionalWidths[screenSize] ?? 0;

  // Присвоїти позицію та ширину modal-search-container
  modalSearchContainer.style.position = 'absolute';
  modalSearchContainer.style.left = `${left - additionalWidth}px`;
  modalSearchContainer.style.top = `${top}px`;
  modalSearchContainer.style.minWidth = `${distance + additionalWidth}px`;
  searchFormTs.style.width = `${rectSearcnsNeighbor.left - 12 - left + additionalWidth}px`;
  searchFormBtn.style.left = `${rectSearcnsNeighbor.left - 12 - left - 32 + additionalWidth}px`;
  searchIpt.setAttribute(
    'style',
    `
		width: ${rectSearcnsNeighbor.right - left}px !important;
	`,
  );
}

// Викликати функцію для встановлення позиції та ширини при завантаженні сторінки
window.addEventListener('load', positionModalSearchContainer);

// Також можна викликати функцію при зміні розміру вікна
window.addEventListener('resize', positionModalSearchContainer, {
  passive: true,
});

document.addEventListener('DOMContentLoaded', () => {
  const query = new URLSearchParams(window.location.search).get('_query');
  if (query) {
    addToLocalStorageArray('last_search', query);
  }
});
